import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { ReactComponent as ShareIcon } from '@icons/wolfkit-light/link-alt-light.svg';
import { ReactComponent as UserIcon } from '@icons/wolfkit-light/user-light.svg';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-light/alert-triangle-light.svg';
import { ReactComponent as SquareIcon } from '@icons/wolfkit-light/copy-square-light.svg';
import { ReactComponent as SquareCheckIcon } from '@icons/wolfkit-light/copy-square-check-light.svg';
import { getAbsolutePath } from '@app/routing/helpers';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import CopyLink from '@shared/ui/misc/CopyLink';
import IconTextComponent from '@shared/ui/icons/IconText';
import ButtonCustomComponent from '@shared/ui/buttons/ButtonCustom';
import Icon from '@shared/ui/icons/Icon';
import Dropdown from '@shared/ui/menus/Dropdown';
import { useAppSelector } from '@app/store/Hooks';
const IconText = styled(IconTextComponent)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const AlertIconText = styled(IconText)(props => ({
    color: props.theme.palette.error.main,
}));
const ButtonCustom = styled(ButtonCustomComponent)(props => ({
    width: '100%',
    transition: 'none',
    color: props.theme.palette.text.secondary,
    border: 'none',
    padding: 0,
    height: 20,
    minHeight: 'unset',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.xs * 1.25,
    '&:hover': {
        background: 'transparent',
        border: 'none',
        color: props.theme.palette.text.secondary,
    },
    '& .MuiButton-startIcon': {
        margin: 0,
    },
    '& .MuiButton-endIcon': {
        margin: 0,
        width: 18,
        height: 18,
    },
}));
const ProfileMoreActionsDropdown = ({ profile, color = 'primary', size = 'medium', variant = undefined, moreActionBtnOptions = undefined, buttonPadding = undefined, disabled = false, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { routes, navigate } = useAppNavigation();
    const [isCopied, setIsCopied] = useState(false);
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const link = `${document.location.origin}${getAbsolutePath(routes.PROFILE, { nickname: profile.username })}`;
    const goToUserProfile = useCallback(() => {
        if (profile.username) {
            if ((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username) && currentUserProfile.username === profile.username) {
                navigate(routes.MY_PROFILE);
            }
            else {
                navigate(routes.PROFILE, { params: { nickname: profile.username } });
            }
        }
    }, [profile, currentUserProfile, navigate, routes]);
    const handleSuccessCopy = () => {
        setIsCopied(true);
    };
    const handleMenuClose = () => {
        setIsCopied(false);
    };
    const defaultOptions = useMemo(() => ([
        {
            itemKey: 'go-to-user',
            children: (_jsx(IconText, { text: t('profile.tabs.action_buttons.visit_profile'), IconComponent: _jsx(Icon, { IconComponent: UserIcon }) })),
            onClick: goToUserProfile,
        },
        {
            itemKey: 'copy-link',
            children: (_jsx(CopyLink, { link: link, onCopySuccess: handleSuccessCopy, children: _jsx(ButtonCustom, { endIcon: (_jsx(Icon, { IconComponent: isCopied ? SquareCheckIcon : SquareIcon, size: 18, color: '#6488F0' })), variant: 'text', children: _jsx(IconText, { text: t('profile.tabs.action_buttons.share'), IconComponent: _jsx(Icon, { IconComponent: ShareIcon }) }) }) })),
            addDivider: true,
        },
        {
            itemKey: 'report',
            children: (_jsx(AlertIconText, { text: t('profile.tabs.action_buttons.report'), IconComponent: _jsx(Icon, { IconComponent: AlertIcon }) })),
        },
    ]), [goToUserProfile, isCopied, t, link]);
    return (_jsx(Dropdown, { options: moreActionBtnOptions || defaultOptions, onClose: handleMenuClose, buttonVariant: variant, buttonColor: color, buttonSize: size, buttonPadding: buttonPadding, menuListStyles: {
            padding: `${theme.spacing_sizes.xs * 0.5}px 0px `,
            minWidth: 240,
        }, disabled: disabled, closeMenuAfterClick: true, zIndex: 2 }));
};
export default ProfileMoreActionsDropdown;
