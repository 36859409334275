var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fade } from '@mui/material';
import { forwardRef, useImperativeHandle, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import Tooltip from '@shared/ui/tooltips/Tooltip';
const TooltipEnterTimeoutMs = 200;
const TooltipHideTimeoutMs = 500;
const TooltipExitTimeoutMs = 400;
const CopyLinkComponent = ({ link, children, onCopySuccess = undefined, }, ref) => {
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const { toast } = useToastNotification();
    const { t } = useTranslation();
    const openTooltip = () => {
        setTooltipIsOpen(true);
        setTimeout(() => {
            setTooltipIsOpen(false);
        }, TooltipHideTimeoutMs);
    };
    const copyLink = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!navigator.clipboard) {
            toast({
                message: t('errors.copy_to_clipboard.unsupported', { ns: 'common' }),
                variant: 'warning',
                displayStyle: 'solid',
            });
            return;
        }
        try {
            yield navigator.clipboard.writeText(link);
            openTooltip();
            if (onCopySuccess) {
                onCopySuccess();
            }
        }
        catch (err) {
            toast({
                message: t('errors.copy_to_clipboard.error', { ns: 'common' }),
                variant: 'error',
                displayStyle: 'solid',
            });
        }
    });
    useImperativeHandle(ref, () => ({
        copyLink,
    }), []);
    return (_jsx(Tooltip, { title: t('statuses.copied', { ns: 'common' }), open: tooltipIsOpen, TransitionComponent: Fade, TransitionProps: {
            timeout: {
                enter: TooltipEnterTimeoutMs,
                exit: TooltipExitTimeoutMs,
            },
        }, placement: 'top', arrow: true, onClick: copyLink, children: children }));
};
const CopyLink = forwardRef(CopyLinkComponent);
export default CopyLink;
